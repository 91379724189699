.emg-sidebar-contact-form {
    top: inherit;
    border: 1px solid #cdcdcc;
    margin-bottom: 30px;
    background: #ffffff;
    padding: 12px 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 10px #ddd;
}

.page-content ol:not(.entry-meta),
.page-content ul:not(.entry-meta) {
    padding-left: 0;
}
.page-content ol:not(.entry-meta) li,
.page-content ul:not(.entry-meta) li{
    position: relative;
    list-style: none;
    padding-left: 30px;
    margin-bottom: 0;
}

.page-content ol:not(.entry-meta) li:not(:last-child),
.page-content ul:not(.entry-meta) li:not(:last-child) {
    margin-bottom: 12px;
}

.page-content ol:not(.entry-meta) li::before,
.page-content ul:not(.entry-meta) li::before {
    content: '\f058';
    font-family: "Font Awesome\ 5 Free";
    font-size: 20px;
    left: 0;
    position: absolute;
    top: 1px;
    font-weight: 900;
    color: #026700;
}
.gform_body ul li:before{
    content: '';
}

.entry-title a {
    color: #333;
    display: block;
}

.entry-meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.entry-meta li {
    list-style: none;
    margin-bottom: 10px;
}
.entry-meta li,
.entry-meta li a {
    // font-size: 14px;
    color: #666;
    line-height: 1;
}

.entry-meta li:not(:last-child) {
    margin-right: 7px;
    border-right: 2px solid #666;
    padding-right: 8px;
    white-space: nowrap;
}
.page-content ul.entry-meta li{
    padding-left: 0;
}

.page-content ul.entry-meta li::before {
    content: '';
}

ul.entry-meta{
    margin-bottom: 0;
}
.page-content a{  
    color: #026700;
}
.page-content h1, .page-content h2, .page-content h3, .page-content h4, .page-content h5, .page-content h6,
.page-content h1 a, .page-content h2 a, .page-content h3 a, .page-content h4 a, .page-content h5 a, .page-content h6 a{
    font-weight: 900;
    font-family: 'Open Sans', sans-serif;
}

/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq-list-item {
  position: relative;
  margin-bottom: 15px;
}
.faq-list-item h3{
    font-size: 18px;
}
.faq-list-item strong {
    cursor: pointer;
    padding-left: 30px;
    font-weight: 900;
    color: #026700;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
}
.faq-list-item .faq-content {
    display: block;
    /*margin-top: -10px;*/
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    /*transition: max-height 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;*/
}
.faq-list-item.open .faq-content{
    /*padding-bottom: 60px;*/
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transition: all 0.9s;
    /*transition: max-height 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;*/
}
.faq-list-item .section-heading {
  position: relative;
    padding: 0 !important;
    text-transform: capitalize;  
}
.faq-list-item .section-heading h2{
    font-size: 17px;
    text-align: left;
    padding: 0 !important;
    padding-left: 30px !important;
    text-transform: capitalize;
    font-weight: 700;
    color: #666 !important;
    margin-bottom: 20px;
    cursor: pointer;
}
.faq-list-item .section-heading h2:before {
    content: '\f055';
    font-family: "Font Awesome\ 5 Free";
    font: normal normal normal 20px/1 FontAwesome;
    font-size: 20px;
    left: 0;
    position: absolute;
    margin-top: 4px;
    font-weight: 900;
    background-color: #000;
    color: #fece0a;
    height: 14px;
    width: 14px;
    top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.faq-list-item.open .section-heading h2:before {
    content: '\f056';
}
.faq-list-item .main_container_class .main_cotent_class {
    display: block;
    /*margin-top: -10px;*/
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    /*transition: max-height 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;*/
}
.faq-list-item.open .main_container_class .main_cotent_class{
    /*padding-bottom: 60px;*/
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transition: all 0.9s;
    /*transition: max-height 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;*/
}
.faq-list-item .main_container_class .main_cotent_class ol li{
  margin-left: 30px;
}
.faq-list-item strong:before {
    content: '\f055';
    font: normal normal normal 20px/1 FontAwesome;
    font-family: "Font Awesome\ 5 Free";
    font-size: 20px;
    left: 0;
    position: absolute;
    margin-top: 4px;
    font-weight: 900;
    background-color: #fff;
    color: #026700;
    height: 14px;
    width: 14px;
      top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.faq-list-item.open strong:before {
    content: '\f056';
}
.wufoo ul li:before{
    content: "";
}
.location-hero{

}
.location-hero .container {
    margin-top: 256px;
    max-height: 210px;
    min-height: 210px;
}

.compaire-hero .container {
    margin-top: 256px;
    max-height: 210px;
    min-height: 210px;
}

.compaire-container{
  margin-top: -285px;
}
section.location-header-area{
    // padding-top: 350px;
    margin-top: -315px;
    border-top: unset;
}
.situation-area.location-situation-area{
    border-top: 1px solid rgba(128,128,128,.2);
}
.city-page-content h2:first-of-type{
    text-align: center;
}
.cities-video{
    background-color: #fff;
    color: #026700;
}
.cities-video h1 {
    font-size: 30px;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    color: #026700;
}
.cities-video h2 {
    font-size: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    color: #026700;
}
.cities-video a h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    color: #026700;
}
.cities-video p {
    // font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
}
.city-list li a{
    margin-left: 40%;
    position: relative;
}
.city-list li:before{
    content: "" !important;
    display: none;
}
.city-list li a:before{
    content: '\f3c5';
    font-family: "Font Awesome\ 5 Free";
    font-size: 20px;
    left: -27px;
    position: absolute;
    top: -3px;
    font-weight: 900;
    color: #026700;
}
.city-list-section{
    border-top: 1px solid rgba(128,128,128,.2);
}
.mean-container a.meanmenu-reveal span{
    display: none;
}
.mean-container a.meanmenu-reveal{
    color: #000;
    right: 0px;
    left: auto;
    text-align: center;
    text-indent: 0px;
    position: relative;
    margin: 55px auto 0 auto;
    border: unset;
    font-size: 18px !important;
    padding-right: 16px;
}
.mean-container .mean-bar{
    right: 0;
}
.mean-container a.meanmenu-reveal:after{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
        position: absolute;
    // margin-left: -8px;
    top: 17px;
    right: 7px;
}
.mean-container a.meanmenu-reveal.meanclose:after{
    border: unset;
}
.cities-video img{
    width: 100%;
    height: auto;
}
.city-page h1{
    text-align: center;
}
.city-list-section {
    padding: 55px 0;
}
.city-list-section h2{
    font-weight: 900;
    color: #026700;
    font-family: 'Open Sans',sans-serif;
}
.selling-section{

}
.selling-section .selling-tab{
    border: unset !important;
}
.selling-tab .selling-tab-header{
    border: unset;
    padding: 0 !important;
    background-color: #fff;
    border: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.selling-tab .selling-tab-header li{
    font-weight: 700;
    list-style: none;
    min-height: 80px;
    width: 30%;    
    display: flex;
    text-align: center;
    justify-content: center;
    // padding-left: 15px;
    align-items: center;
    /*min-width: 388px;*/
    border: 1px solid #e7e7e9;
    border-bottom: unset !important;
    box-shadow: unset !important;
}
.selling-tab .selling-tab-header li a{    
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    min-height: 80px;
    min-width: 100%;
    // padding-left: 15px;
    text-align: center;
    justify-content: center;
}
.selling-tab .selling-tab-header li{
    margin: 0 !important
}
.selling-tab .selling-tab-header li:nth-child(1){
    width: 40%
}
.selling-tab .selling-tab-header li:nth-child(2){
    // width: 68%
}
.selling-tab .selling-tab-header li:nth-child(2) a{
    // background-color: #06afe2;
    color: #212529;
}
.selling-tab .selling-tab-header li:nth-child(3) a{
    // background-color: #333335;
    color: #212529;
}
.selling-tab .selling-tab-header li img{
    max-width: 331px;
    width: 100%;
    height: auto;
}
.selling-list{
    display: flex;
    flex-direction: column;
}
.selling-list .selling-box-row{
    display: flex;
    flex-direction: row;
}
.selling-list .selling-box-row:nth-child(2n+2){
    // background-color: #f0f5fa;
}
.selling-list .selling-box-row .selling-box-column:nth-child(3){
    background-color: #f0f5fa;
}
.selling-list .selling-box-row .selling-box-column{
    color: #000;
    display: flex;
    flex-direction: column;
    border: 1px solid #e7e7e9;
    width: -webkit-fill-available;
    padding: 0 15px;
    min-width: 30%;
    min-height: 60px;
    justify-content: center;
    text-align: center;
    // font-size: 16px;
}

.selling-list .selling-box-row .selling-box-column:nth-child(1){
    font-size: 18px;
    font-weight: 500;
    min-width: 40%;
    // text-align: left;
    font-weight: 600;
}
.selling-list .selling-box-row .selling-box-column:nth-child(3){
    font-weight: 700;
    color: #026700;
}
.selling-tab .selling-body{
    border: unset;
    padding: 0 !important;

}
.content-section{
    padding: 80px 0 70px 0;
}
@media only screen and (max-width: 1199px) { 
    section.situation-area.location-header-area{
        // padding-top: 400px;
    }
    .city-list li a {
        margin-left: 38%;
    }
}
@media only screen and (max-width: 991px) { 
    .city-list li a {
        margin-left: 14%;
    }
    .location-hero .container {
        margin-top: 200px;
    }
    section.situation-area.location-header-area{
        // padding-top: 700px;
    }
}
@media only screen and (max-width: 767px) { 
    .mainmenu-area {
        height: 150px;
    }
    .cities-video h1 {
        font-size: 30px;
    }
    section.situation-area.location-header-area{
        // padding-top: 800px;
    }
    .page-content ul.city-list li{
        padding-left: 0;
    }
    .selling-tab .selling-tab-header li {
        min-height: 117px; 
    }
    .city-list-section {
        padding: 23px 0;
    }
}
@media only screen and (max-width:575px) { 
    .city-list li a {
        margin-left: 15%;
    }
    section.situation-area.location-header-area{
        // padding-top: 1023px;
    }
    .selling-tab .selling-tab-header li img{
        max-width: 45px;
    }
}
@media only screen and (max-width:519px) { 
    section.situation-area.location-header-area{
        // padding-top: 1050px;
    }
}
@media only screen and (max-width: 488px) { 
    .location-hero .container {
        margin-top: 160px;
    }
    section.situation-area.location-header-area{
        // padding-top: 1100px;
    }
    .selling-list .selling-box-row .selling-box-column{
        padding: 0 5px;
    }
    // .city-list li a {
    //     margin-left: 8%;
    // }
}
@media only screen and (max-width: 420px) { 
    section.situation-area.location-header-area{
        // padding-top: 1156px;
    }
    .selling-list .selling-box-row .selling-box-column{
        padding: 0 2px;
    }
    .selling-list .selling-box-row .selling-box-column{
        font-size: 17px;
    }
}
@media only screen and (max-width: 380px) { 
    section.situation-area.location-header-area{
        // padding-top: 1180px;
    }
    .selling-tab .selling-tab-header li {
        min-height: 141px; 
    }
}