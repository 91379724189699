
.bg-gray {
    background: #f0f0f0;
}
.bg-dark {
    background: #000 !important;
}
.top {
    background: #026700;
}
.top .social-media > a {
    border-left: 1px solid #025800;
    width: 42px;
}
.top .social-media > a:last-child {
    border-right: 1px solid #025800;
}

nav.navbar {
    background: #ebebeb;
    padding: 0;
}

nav.navbar button.navbar-toggler {
    color: #383838;
    font-weight: 700;
}

.navbar-nav > li {
    position: relative;
}

.navbar-nav > li.nav-item > a {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    color: #383838;
    padding: 15px 10px !important;
    line-height: 1.3;
    position: relative;
}

.navbar-nav > li.nav-item.dropdown > a {
    padding-right: 25px !important;
}

.navbar .nav-item:hover .nav-link {
    color: #383838;
}

.navbar-nav > li.dropdown > .nav-arrow {
    width: 14px;
    height: 14px;
    background-image: url(/assets/img/arrow-down.png);
    background-repeat: no-repeat;
    background-size: 100%;


    position: absolute;
    top: 22px;
    right: 6px;
    transition: transform .5s ease-out;
    cursor: pointer;
}


@media screen and (min-width: 991px) {
  

    .navbar-nav > li.nav-item > a {
        z-index: 1;
    }

    .navbar-nav > li.dropdown > .nav-arrow {
        z-index: 0;
    }

    .navbar-nav > li.dropdown:hover > .nav-arrow {
        transform: rotate(180deg);
    }

    .navbar-nav > li.nav-item > a {
        padding: 20px 10px !important;
    }

    .navbar .nav-item .dropdown-menu {
        border-radius: 0;
        border-top: 5px solid #026700;
    }

    .nav-item.dropdown > .dropdown-menu > li {
        margin-bottom: 0;
        font-size: 16px;
    }

    .dropdown-item.active,
    .nav-item.dropdown > .dropdown-menu > li:hover > a {
        color: #fff;
        background: #026700;
    }

}

@media screen and (min-width: 1199px) {

    .navbar-nav > li.nav-item > a {
        font-size: 16px;
        padding: 20px 15px !important;
    }

}


@media screen and (max-width: 991px) {

    nav.navbar {
        padding: 8px 0;
    }

    .navbar-nav li {
        margin-bottom: 0;
    }

    .navbar-nav .dropdown-menu {
        padding: 0;
        margin: 0;
        border: 0;
        background: rgba(250,250,250, .34);
    }

    .navbar-nav > li.dropdown > .nav-arrow {
        width: 40px;
        background-size: auto;
        height: 40px;
        background-position: center;
        top: 0;
        right: 0;
    }

}


.banner-section {
    background: url(/assets/img/banner.jpg) no-repeat 50%;
    background-size: cover;
    padding: 135px 0;
    background-position: top;
}

.banner-section .banner-content h1 {
    color: #fff;
}
.portfolio .container > .row > div .image-container {
    background-color: #f6f6f6;
    padding: 9% 9% 0;
    margin-bottom: 10%;
}
.portfolio .container > .row > div .image-container img {
    width: 100%;
}

.pricing .wrap-pricing {
    background: linear-gradient(90deg, #fff 60%, #f2f7f2 0);
    padding: 80px 0;
}

.submit-form,
.pricing .submit-form {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    background: #fff;
}
.submit-form form > div > input,
.submit-form textarea,
.pricing .submit-form form > div > input,
.pricing .submit-form textarea {
    border: 1px solid #ebebeb;
    padding: 10px 20px;
}
.submit-form form > div > input::-moz-placeholder,
.submit-form textarea::-moz-placeholder,
.pricing .submit-form form > div > input::-moz-placeholder,
.pricing .submit-form textarea::-moz-placeholder {
    color: #999;
}
.submit-form form > div > input:-ms-input-placeholder,
.submit-form textarea:-ms-input-placeholder,
.pricing .submit-form form > div > input:-ms-input-placeholder,
.pricing .submit-form textarea:-ms-input-placeholder {
    color: #999;
}
.submit-form form > div > input::placeholder,
.submit-form textarea::placeholder,
.pricing .submit-form form > div > input::placeholder,
.pricing .submit-form textarea::placeholder {
    color: #999;
}
.submit-form form > div > textarea,
.pricing .submit-form form > div > textarea {
    color: #999;
}
.submit-form .submit-btn input,
.pricing .submit-form .submit-btn input {
    width: 100%;
}
.money-roofing .wrap-money-roofing {
    background: linear-gradient(90deg, #f2f7f2 40%, #fff 0);
    padding: 80px 0;
}
.project .container > .row > div .image-container {
    background-color: #f6f6f6;
    // padding: 20px;
    margin-bottom: 30px;
    height: 360px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border: 20px solid #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.project .container > .row > div .image-container img {
    width: 100%;
    min-height: 314px;
    height: auto;
    width: 100%;
    box-shadow: unset;
}
.project .container > .row > div .image-container.horizontal-img{
    overflow: hidden;
}
.project .container > .row > div .image-container.horizontal-img img{
    width: auto;
    min-width: max-content;
    max-height: 314px;
}
footer.footer .footer-banner {
    background: url(/assets/img/bg-1.png) 0 0 no-repeat, url(/assets/img/bg2.png) 100% 0 no-repeat;
    position: relative;
    background-color: #026700;
    z-index: 3;
}
footer.footer .footer-banner #overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 81, 0, 0.9);
    z-index: -1;
    // cursor: pointer;
}
footer.footer .footer-banner h3 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
}

.footer-banner .h1 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 60px;
}
footer.footer .footer-banner a {
    // color: #026700;
    // font-weight: 700;
    // padding: 15px 25px;
}
footer.footer .footer-banner a.primary-btn  {
    background: #fff;
    color: #026700;
    font-weight: 700;
    padding: 10px 25px;
}
footer.footer .footer-copyright {
    background: #023601;
}
footer.footer .footer-copyright p {
    color: #fff;
}
.primary-btn {
    font-size: 18px;
    background-color: #026700;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
}

@media screen and (max-width: 1680px) {

    .banner-section {
        padding: 115px 0;
    }

}

@media screen and (max-width: 1199px) {

    .banner-section {
        padding: 80px 0 60px;
    }

    .banner-section .banner-content h1 {
        font-size: 35px;
    }

    .money-roofing .wrap-money-roofing {
        background: linear-gradient(90deg, #f2f7f2 25%, #fff 0);
        padding: 60px 0;
    }

    
    .pricing .wrap-pricing {
        background: linear-gradient(90deg, #fff 70%, #f2f7f2 0);
        padding: 60px 0;
    }

    p {
        font-size: 16px;
    }
    h1 {
        font-size: 50px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 30px;
    }
    .h5,
    h5 {
        font-size: 18px;
    }
    footer.footer .footer-banner h3 {
        font-size: 20px;
    }

    .footer-banner .h1 {
        font-size: 30px;
    }
}

@media screen and (max-width: 991px) {
    p {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) { 
    h1 {
      font-size: 27px;
    }

    h2 {
      font-size: 23px;
    }

    h3 {
      font-size: 21px;
    }

    h4 {
      font-size: 19px;
    }

    h5 {
      font-size: 17px;
    }

    h6 {
      font-size: 15px;
    }
    footer.footer .footer-banner h3 {
        font-size: 16px;
    }
    p {
        font-size: 16px;
    }
    .pricing .wrap-pricing .pricing-content {
        margin-bottom: 10%;
    }
    .money-roofing .wrap-money-roofing .pricing-content {
        margin-top: 10%;
    }
    footer.footer .footer-banner {
        background: url(./assets/img/bg-1.png) 0 0 no-repeat;
    }
    .second-top p {
        text-align: center;
    }

    .pricing .wrap-pricing,
    .money-roofing .wrap-money-roofing {
        background: #fff !important;
    }


}

@media screen and (max-width: 575px) {

    .banner-section {
        padding: 60px 0;
    }

    .banner-section .banner-content h1 {
        font-size: 30px;
        font-weight: 700;
    }

}


.site-content img[class*=wp-image-] {
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;
}

body {
    background: #fff;
}


form#form199 label.error {
    color: red;
    font-size: 15px;
    margin: 3px 0 0;
}

@media screen and (max-width: 360px) {
    .submit-form .submit-btn input {
        font-size: 16px;
    }
}
.g-recaptcha {
    margin: 0 auto;
    display: table;
}
.alignleft {
    display: inline-block;
    float: left;
    margin-right: 1.5em;
}
.alignright {
    display: inline-block;
    float: right;
    margin-left: 1.5em;
}
.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.aligncenter,
.alignleft,
.alignright {
    margin-bottom: 15px;
}
.alignright,
.alignleft,
.aligncenter {
    display: inline-block;
    margin-bottom: 15px;
}
@media screen and ( max-width: 768px ) {
    .alignright,
    .alignleft,
    .aligncenter {
    margin: 0 auto 15px !important;
        display: block;
        float: none !important;
    }
}
.form-control-group {
    display: none;
}
