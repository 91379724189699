body {
    margin: 0;
    font-family: Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 400;
    background-color: #fff;
    color: #000;
}
img {
    border-style: none;
    max-width: 100%;
}
.weight-bold {
    font-weight: 700;
}
.brand-color {
    color: #026700;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: sans-serif;
    font-weight: 600;
    color: #000;
}
h1 {
    margin: 0 0 15px;
    font-size: 45px;
    line-height: 1.2;
}
h2 {
    font-size: 35px;
}
h2,
h3 {
    line-height: 1.3;
}
h3 {
    font-size: 35px;
}
h4 {
    font-size: 30px;
}
h5 {
    font-size: 22px;
}
h6 {
    font-size: 18px;
}
.h1 {
    margin: 0 0 15px;
    font-size: 60px;
}
.h1,
.h2 {
    line-height: 1.2;
}
.h2 {
    font-size: 45px;
}
.h3 {
    font-size: 35px;
}
.h3,
.h4 {
    line-height: 1.3;
}
.h4 {
    font-size: 30px;
}
.h5 {
    font-size: 22px;
}
.h6 {
    font-size: 18px;
}
.small-size-text {
    font-size: 16px;
}
p {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}
a {
    transition: 0.3s;
    color: #fff;
}
a:focus,
a:hover {
    text-decoration: none;
    outline: none;
    color: #fff;
}
li {
    // list-style: none;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 7px;
}

ul, ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.readmore{
    font-weight: 500;
}
.footer p,
.second-top p{
    line-height: 24px;
}
.callus-btn h4{
    color: #383838;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .dropdown-menu{ display: block; }
    .navbar .nav-item .dropdown-menu{ margin-top:0; }
}   
/* ============ desktop view .end// ============ */
.dropdown-item.active, .dropdown-item:active{
    background: #ebebeb;
}
.dropdown-item.active, .dropdown-item{
    padding: 10px 13px !important;
}
.navbar .nav-item{
    margin-bottom: 0;
}
@media (min-width: 992px){
    .dropdown-menu .dropdown-toggle:after{
        border-top: .3em solid transparent;
        border-right: 0;
        border-bottom: .3em solid transparent;
        border-left: .3em solid;
    }
    .dropdown-menu .dropdown-menu{
        margin-left:0; margin-right: 0;
    }
    .dropdown-menu li{
        position: relative;
    }
    .nav-item .submenu{ 
        display: none;
        position: absolute;
        left:100%; top:-7px;
        visibility: hidden;
    }
    .nav-item .submenu-left{ 
        right:100%; left:auto;
    }
    .dropdown-menu > li:hover{ background-color: #f1f1f1 }
    .dropdown-menu > li:hover > .submenu{
        display: block;
    }
    .nav-item .has-child-submenu>.submenu{ 
        visibility: hidden;    
    }
    .nav-item .has-child-submenu:hover>.submenu{
        visibility: visible;
        // transition: 0.3s;
    }
}